/* ==========================================================================
   ELEMENTS / #LINKS
   ========================================================================== */

/**
 * Our basic `<a>` elements only need very minimal styling.
 * It uses the @mixin nhsuk-link-style-default within tools/links.
 * Anything more opinionated (e.g. buttons, calls-to-action, etc.) will need a
 * class defining in the Components layer.
 *
 * 1. Outputs full link URL for print.
 * 2. Point unit used for print.
 */

a {
  @include nhsuk-link-style-default;

  @include mq($media-type: print) {
    &:after {
      color: $color_nhsuk-black;
      content: ' (Link: ' attr(href) ')'; /* [1] */
      font-size: 14pt; /* [2] */
    }
  }

}

.nhsuk-link--no-visited-state {
  @include nhsuk-link-style-no-visited-state;
}
