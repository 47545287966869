/* ==========================================================================
   UTILITIES / #GRID
   ========================================================================== */

/**
 * Force grid widths on all screen sizes
 * By default all grid elements will go to 100% width
 * on screen sizes below tablet, these utilities can force
 * custom widths on all screen sizes
 *
 * Usage: class="nhsuk-u-one-half"
 */

// Utility classes are allowed to use !important;
// so we disable stylelint for that rule
/* stylelint-disable declaration-no-important */

.nhsuk-u-one-half {
  float: left;
  width: percentage(1 / 2) !important;
}

.nhsuk-u-one-third {
  float: left;
  width: percentage(1 / 3) !important;
}

.nhsuk-u-two-thirds {
  float: left;
  width: percentage(2 / 3) !important;
}

.nhsuk-u-one-quarter {
  float: left;
  width: percentage(1 / 4) !important;
}

.nhsuk-u-three-quarters {
  float: left;
  width: percentage(3 / 4) !important;
}

/**
 * Force grid widths on screen sizes on tablet
 * and above
 *
 * By default all grid elements will go to 100% width
 * on every screen size, these utilities can force
 * custom widths on screen sizes on tablet
 * and above
 *
 * Usage: class="nhsuk-u-one-half-tablet"
 */

.nhsuk-u-one-half-tablet {
  width: 100% !important;
  @include mq($from: tablet) {
    float: left;
    width: percentage(1 / 2) !important;
  }
}

.nhsuk-u-one-third-tablet {
  width: 100% !important;
  @include mq($from: tablet) {
    float: left;
    width: percentage(1 / 3) !important;
  }
}

.nhsuk-u-two-thirds-tablet {
  width: 100% !important;
  @include mq($from: tablet) {
    float: left;
    width: percentage(2 / 3) !important;
  }
}

.nhsuk-u-one-quarter-tablet {
  width: 100% !important;
  @include mq($from: tablet) {
    float: left;
    width: percentage(1 / 4) !important;
  }
}

.nhsuk-u-three-quarters-tablet {
  width: 100% !important;
  @include mq($from: tablet) {
    float: left;
    width: percentage(3 / 4) !important;
  }
}
