/* ==========================================================================
   COMPONENTS / #WARNING-CALLOUT
   ========================================================================== */

/**
 * 1. Uses @mixin panel-with-label from tools/_mixins.
 * 2. Uses @mixin heading-label from tools/_mixins.
 */

.nhsuk-warning-callout {
  @include panel-with-label($color_nhsuk-pale-yellow, $nhsuk-text-color, $color_nhsuk-yellow); /* [1] */
}

.nhsuk-warning-callout__label {
  @include heading-label($color_nhsuk-yellow, $nhsuk-text-color); /* [2] */
}
