/* ==========================================================================
   OBJECTS / #WIDTH-CONTAINER
   ========================================================================== */

/**
 * Page width for the grid system
 *
 * Original code taken from GDS (Government Digital Service)
 * https://github.com/alphagov/govuk-frontend
 *
 * 1. On mobile, add half width gutters
 * 2. Limit the width of the container to the page width
 * 3. From desktop, add full width gutters
 * 4. As soon as the viewport is greater than the width of the page plus the
 *    gutters, just centre the content instead of adding gutters.
 * 5. Full width container, spanning the entire width of the viewport
 */

@mixin govuk-width-container {
  margin: 0 $nhsuk-gutter-half; /* [1] */

  max-width: $nhsuk-page-width; /* [2] */

  @include govuk-media-query($from: desktop) {
    margin: 0 $nhsuk-gutter; /* [3] */
  }

  /* [4] */
  @include govuk-media-query($and: '(min-width: #{($nhsuk-page-width + $nhsuk-gutter * 2)})') {
    margin: 0 auto;
  }
}

@mixin nhsuk-width-container-fluid {
  margin: 0 $nhsuk-gutter-half;
  max-width: 100%; /* [5] */

  @include govuk-media-query($from: desktop) {
    margin: 0 $nhsuk-gutter; /* [3] */
  }
}

@include govuk-exports('govuk/objects/width-container') {
  .nhsuk-width-container {
    @include govuk-width-container;
  }
  .nhsuk-width-container-fluid {
    @include nhsuk-width-container-fluid;
  }
}
