/* ==========================================================================
   UTILITIES / #CLEARFIX
   ========================================================================== */

/**
 * Automatically clear an elements
 * child elements
 *
 * Usage: class="nhsuk-u-clear"
 * See tools/mixins
 */

.nhsuk-u-clear {
  @include clearfix();
}
