// ==========================================================================
// TOOLS / #FUNCTIONS
// ==========================================================================

// Convert pixels to em
// ==========================================================================

//
// A quick way to convert px into em
// Usage: nhsuk-em(20px, $nhsuk-base-font-size);
//
// Original code taken from GDS (Government Digital Service)
// https://github.com/alphagov/govuk-frontend
//

@function nhsuk-em($value, $context-font-size) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }
  @if (unitless($context-font-size)) {
    $context-font-size: $context-font-size * 1px;
  }
  @return $value / $context-font-size * 1em;
}

// Convert pixels to rem
// ==========================================================================

//
// A quick way to convert px into rem
// Usage: nhsuk-px-to-rem(20px);
//
// The $nhsuk-base-font-size (defined in settings/_globals.scss)
// must be configured to match the font-size of your root (html) element
//

@function nhsuk-px-to-rem($value) {
  @if (unitless($value)) {
    $value: $value * 1px;
  }

  @return $value / $nhsuk-base-font-size * 1rem;
}
