/* ==========================================================================
   UTILITIES / #DISPLAY
   ========================================================================== */

/* stylelint-disable declaration-no-important */

.nhsuk-u-display-block {
  display: block !important;
}

.nhsuk-u-display-inline-block {
  display: inline-block !important;
}
