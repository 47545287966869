// ==========================================================================
// TOOLS / #SASS-MQ
// ==========================================================================

//
// We use sass-mq module for media queries
//
// 1. Import sass-mq from our vendor dependencies folder opposed to node_modules
// in case node_modules is not installed in the folder relative to the root folder
//

$sass-mq-already-included: false !default;

@if $sass-mq-already-included {
  $mq-show-breakpoints: ();
}

@import '../vendor/sass-mq'; // [1] //

$sass-mq-already-included: true;

//
// sass-mq helpers
//
// Original code taken from GDS (Government Digital Service)
// https://github.com/alphagov/govuk-frontend
//

@mixin govuk-media-query($args...) {
  @include mq($args...) {
    @content;
  };
}
