.nhsuk-form-group {
  @include nhsuk-responsive-margin(4, 'bottom');

  .nhsuk-form-group:last-of-type {
    margin-bottom: 0; // Remove margin from last item in nested groups
  }
}

.nhsuk-form-group--wrapper {
  @include nhsuk-responsive-margin(5, 'bottom');
}

.nhsuk-form-group--error {
  border-left: $nhsuk-border-width-form-group-error solid $nhsuk-error-color;
  padding-left: nhsuk-spacing(3);

  .nhsuk-form-group {
    // Reset error styles in nested form groups that might have error class
    border: 0;
    padding: 0;
  }
}
