// ==========================================================================
// SETTINGS / #BREAKPOINTS
// ==========================================================================

//
// sass-mq config - https://github.com/sass-mq/sass-mq
//
// mq() takes up to three optional parameters:
// $from: inclusive min-width boundary
// $until: exclusive max-width boundary
// $and: additional custom directives
// $media-type: $mq-media-type // defaults to 'all'
//
// 1. To enable support for browsers that do not support @media queries,
//    (IE <= 8, Firefox <= 3, Opera <= 9) set $mq-responsive to false
//    Create a separate stylesheet served exclusively to these browsers,
//    meaning @media queries will be rasterized, relying on the cascade itself
// 2. Define the breakpoint from the $mq-breakpoints list that should
//    be used as the target width when outputting a static stylesheet
//    (i.e. when $mq-responsive is set to 'false').
///

$mq-responsive: true; // [1] //

$mq-breakpoints: (
  mobile:  320px,
  tablet:  641px,
  desktop: 769px,
  large-desktop: 990px
);

$mq-static-breakpoint: desktop; // [2] //
