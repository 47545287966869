/* ==========================================================================
   COMPONENTS/ #FIELDSET
   ========================================================================== */

/**
 * 1. Fix legend text wrapping in Edge and IE versions:
 * 2. IE9-11 & Edge 12-13
 * 3. Hack to let legends or elements within legends have margins in webkit browsers.
 * 4. When the legend contains an H1, we want the H1 to inherit all styles from
 *    the legend. Effectively we want to be able to treat the heading as if it is
 *    not there.
 */

.nhsuk-fieldset {
  @include clearfix;

  border: 0;
  margin: 0;
  padding: 0;
}

.nhsuk-fieldset__legend {
  @include nhsuk-font(19);

  box-sizing: border-box; /* 1 */
  color: $color_nhsuk-black;
  display: table; /* 2 */
  margin-bottom: nhsuk-spacing(2);
  margin-top: 0;
  max-width: 100%; /* 1 */
  padding: 0;
  white-space: normal;    /* 3 */
}

/* Heading modifiers */

.nhsuk-fieldset__legend--xl {
  @include nhsuk-font($size: 48, $weight: bold);
  margin-bottom: nhsuk-spacing(3);
}

.nhsuk-fieldset__legend--l {
  @include nhsuk-font($size: 32, $weight: bold);
  margin-bottom: nhsuk-spacing(3);
}

.nhsuk-fieldset__legend--m {
  @include nhsuk-font($size: 24, $weight: bold);
  margin-bottom: nhsuk-spacing(3);
}

.nhsuk-fieldset__legend--s {
  @include nhsuk-font($size: 19, $weight: bold);
  margin-bottom: nhsuk-spacing(3);
}

.nhsuk-fieldset__heading { /* 4 */
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}
