// ==========================================================================
// SETTINGS / #GLOBALS
// ==========================================================================

//
// Fonts
//
// 1. Fallback fonts if Frutiger fails to load
//

$nhsuk-font: Frutiger W01 !default;
$nhsuk-font-fallback: Arial, Sans-serif !default; // [1] //
$nhsuk-font-family-print: sans-serif !default;
$nhsuk-font-bold: 600 !default;
$nhsuk-font-normal: 400 !default;
$nhsuk-font-light: $nhsuk-font-normal !default;
$nhsuk-fonts-path: 'https://assets.nhs.uk/fonts/' !default;
$nhsuk-include-font-face: true !default;

//
// Font sizing and spacing
//

$nhsuk-base-font-size: 16px !default;
$nhsuk-base-line-height: 24px !default;

//
// Icon default sizing
//

$nhsuk-icon-size: 34px !default;

//
// Grid spacing
//

$nhsuk-page-width: 960px;
$nhsuk-gutter: 32px;
$nhsuk-gutter-half: $nhsuk-gutter / 2;

//
// Border sizes
//

$nhsuk-border-width-inset-text: 8px !default;
$nhsuk-care-card-triangle-border: 16px !default;
$nhsuk-hero-content-triangle-border: 16px !default;
$nhsuk-hero-border: 1px !default;
$nhsuk-border-table-header-width: 2px !default;
$nhsuk-border-table-cell-width: 1px !default;

//
// Border radius
//

$nhsuk-border-radius: 4px !default;

//
// Box shadow
//

$nhsuk-box-shadow-spread: 4px !default;
$nhsuk-box-shadow-blur: 4px !default;
$nhsuk-box-shadow-link: 4px !default;
$nhsuk-box-details: 8px !default;
$nhsuk-box-expander: 4px !default;
$nhsuk-box-shadow-pagination: 16px !default;
$nhsuk-box-shadow-link: 4px !default;

//
// Header spacing
//

$nhsuk-header-spacing: 20px;

//
// Form elements
//

$nhsuk-border-width-form-element: 2px !default;
$nhsuk-border-width-form-element-error: 4px !default;
$nhsuk-focus-width: 4px !default;
$nhsuk-border-width: 4px !default;
$nhsuk-border-width-mobile: 4px !default;
$nhsuk-border-width-form-group-error: $nhsuk-border-width !default;
