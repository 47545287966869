// ==========================================================================
// SETTINGS / #FONT-FACE
// ==========================================================================

// NHS uses the Frutiger font, it was originally made
// for print, but we've had to adapt for web.
// https://assets.nhs.uk/fonts/nhsuk-fonts-1.3.0.css

/* stylelint-disable indentation */

@function nhsuk-font-url($filename) {
  @return url($nhsuk-fonts-path + $filename);
}

@mixin _nhsuk-font-face-frutiger {
  @at-root {
    @font-face {
      font-display: swap;
      font-family: 'Frutiger W01';
      font-style: normal;
      font-weight: 400;
      src: nhsuk-font-url('FrutigerLTW01-55Roman.eot?#iefix');
      src: nhsuk-font-url('FrutigerLTW01-55Roman.eot?#iefix') format('eot'),
           nhsuk-font-url('FrutigerLTW01-55Roman.woff2') format('woff2'),
           nhsuk-font-url('FrutigerLTW01-55Roman.woff') format('woff'),
           nhsuk-font-url('FrutigerLTW01-55Roman.ttf') format('truetype'),
           nhsuk-font-url('FrutigerLTW01-55Roman.svg#7def0e34-f28d-434f-b2ec-472bde847115') format('svg');
    }

    @font-face {
      font-display: swap;
      font-family: 'Frutiger W01';
      font-style: normal;
      font-weight: $nhsuk-font-bold;
      src: nhsuk-font-url('FrutigerLTW01-65Bold.eot?#iefix');
      src: nhsuk-font-url('FrutigerLTW01-65Bold.eot?#iefix') format('eot'),
           nhsuk-font-url('FrutigerLTW01-65Bold.woff2') format('woff2'),
           nhsuk-font-url('FrutigerLTW01-65Bold.woff') format('woff'),
           nhsuk-font-url('FrutigerLTW01-65Bold.ttf') format('truetype'),
           nhsuk-font-url('FrutigerLTW01-65Bold.svg#eae74276-dd78-47e4-9b27-dac81c3411ca') format('svg');
    }
  }
}
