/* ==========================================================================
   UTILITIES / #READING-WIDTH
   ========================================================================== */

/**
 * Reading width mixin, add a maximum width
 * to large pieces of content
 *
 * Usage: class="nhsuk-u-reading-width"
 * See tools/mixins
 */

.nhsuk-u-reading-width {
  @include reading-width();
}
