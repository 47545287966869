/* ==========================================================================
   UTILITIES / #VISUALLY-HIDDEN
   ========================================================================== */

/**
 * Hide elements visually but keep it in the DOM
 *
 * Usage: class="nhsuk-u-visually-hidden"
 * See tools/mixins
 */

.nhsuk-u-visually-hidden {
  @include visually-hidden();
}
