// ==========================================================================
// TOOLS / #EXPORTS
// ==========================================================================

//
// Exports are used to ensure that the modules of CSS we define throughout Frontend
// are only included in the generated CSS once, no matter how many times they
// are included e.g. by individual components.
//
//
// 1. List of modules which have already been exported
// 2. If the mixin is not in the list of modules already exported...
// 3. then add it to the list
// 4. ... and output the CSS for that module
// 5. The next time exports is called for the module of the same name,
//    it will be found in the list and so nothing will be outputted.
//
// Original code taken from GDS (Government Digital Service)
// https://github.com/alphagov/govuk-frontend
//

$imported-modules: () !default; // [1] //

@mixin govuk-exports($name, $warn: true) {
  @if (index($imported-modules, $name) == null) { // [2] //
    $imported-modules: append($imported-modules, $name) !global; // [3] //
    @content; // [4] //
  } // [5] //
}
