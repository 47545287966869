// ==========================================================================
// TOOLS / #LINKS
// ==========================================================================

//
// Default link styling
//
// Usage: @include nhsuk-link-style-default;
//

@mixin nhsuk-link-style-default {

  color: $nhsuk-link-color;

  &:visited {
    color: $nhsuk-link-visited-color;
  }

  &:hover {
    color: $nhsuk-link-hover-color;
    text-decoration: none;
  }

  &:focus {
    @include nhsuk-focused-text();

    &:hover {
      text-decoration: none;
    }

    &:visited {
      color: $nhsuk-focus-text-color;
    }

    .nhsuk-icon {
      fill: $nhsuk-focus-text-color;
    }
  }

  &:active {
    color: $nhsuk-link-active-color;
  }

}

//
// White link styling, used in the footer.
//
// Usage: @include nhsuk-link-style-white;
//

@mixin nhsuk-link-style-white {

  color: $color_nhsuk-white;

  &:visited {
    color: $color_nhsuk-white;
  }

  &:hover {
    color: $color_nhsuk-white;
    text-decoration: none;
  }

  &:focus {
    color: $nhsuk-focus-text-color;
    outline: $nhsuk-focus-width solid transparent;
    outline-offset: $nhsuk-focus-width;
    text-decoration: none;
  }

  &:active {
    color: $nhsuk-link-active-color;
  }

}

//
// Default link hover only styling
//
// Usage: @include nhsuk-link-style-hover;
//

@mixin nhsuk-link-style-hover {
  &:hover {
    text-decoration: none;
  }
}

/// No visited state link mixin
///
/// Used in cases where it is not helpful to distinguish between visited and
/// non-visited links.
///
/// For example, navigation links to pages with dynamic content like admin
/// dashboards. The content on the page is changing all the time, so the fact
/// that you’ve visited it before is not important.
///
/// If you use this mixin in a component you must also include the
/// nhsuk-link-style-default mixin in order to get the focus state.
///
/// @example scss
///   .nhsuk-component__link {
///     @include nhsuk-link-style-default;
///     @include nhsuk-link-style-no-visited-state;
///   }
///

@mixin nhsuk-link-style-no-visited-state {
  &:link {
    color: $nhsuk-link-color;
  }

  &:visited {
    color: $nhsuk-link-color;
  }

  &:hover {
    color: $nhsuk-link-hover-color;
  }

  &:active {
    color: $nhsuk-link-active-color;
  }

  &:focus {
    color: $nhsuk-focus-text-color;
  }
}
