/* ==========================================================================
   UTILITIES / #TYPOGRAPHY
   ========================================================================== */

// Utility classes are allowed to use !important;
// so we disable stylelint for that rule

/**
 * Font size and line height
 *
 * Generate typography override classes for each responsive font map in the
 * typography scale eg .nhsuk-u-font-size-48
 *
 * Original code taken from GDS (Government Digital Service)
 * https://github.com/alphagov/govuk-frontend
 */

@each $size in map-keys($nhsuk-typography-scale) {
  .nhsuk-u-font-size-#{$size} {
    @include nhsuk-typography-responsive($size, $important: true);
  }
}

/* Weights
   ========================================================================== */

/**
 * Generate font weight override classes for normal and bold
 * eg .nhsuk-u-font-weight-normal
 */

.nhsuk-u-font-weight-normal {
  @include nhsuk-typography-weight-normal($important: true);
}

.nhsuk-u-font-weight-bold {
  @include nhsuk-typography-weight-bold($important: true);
}

/* Colours
   ========================================================================== */

/**
 * Secondary text colour $nhsuk-secondary-text-color
 * eg <p class="nhsuk-u-secondary-text-color">Published on: 15 March 2018</p>
 */

.nhsuk-u-secondary-text-color {
  color: $nhsuk-secondary-text-color !important; /* stylelint-disable-line declaration-no-important */
}
