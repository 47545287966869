/* ==========================================================================
   UTILITIES / #FLOAT
   ========================================================================== */

/* stylelint-disable declaration-no-important */

.nhsuk-u-float-left {
  float: left !important;
}

.nhsuk-u-float-right {
  float: right !important;
}
