/* ==========================================================================
   COMPONENTS / #IMAGES
   ========================================================================== */

/**
 * 1. Overrides default browser margin values.
 * 2. Makes the image width two thirds of its container for desktop.
 * 3. Avoid image printing full width of a page.
 * 4. Removes top margin from subsequent nhsuk-image.
 */

.nhsuk-image {
  background-color: $color_nhsuk-white;
  border-bottom: 1px solid $color_nhsuk-grey-4;

  margin-left: 0; /* [1] */
  margin-right: 0; /* [1] */

  @include nhsuk-responsive-margin(6, 'bottom');
  @include nhsuk-responsive-margin(6, 'top');

  @include mq($from: desktop) {
    width: 66.66667%; /* [2] */
  }

  @include mq($media-type: print) {
    width: 50%; /* [3] */
  }

  & + .nhsuk-image {
    @include nhsuk-responsive-margin(0, 'top'); /* [4] */
  }

}

.nhsuk-image__img {
  display: block;
  width: 100%;
}

.nhsuk-image__caption {
  @include nhsuk-typography-responsive(16);

  padding: nhsuk-spacing(3);
}
