/* ==========================================================================
   ELEMENTS / #PAGE
   ========================================================================== */

/**
 * High-level, page-level styling.
 *
 * 1. The reason for this is to prevent "centering jumps" when navigating back
 *    and forth between pages with enough content to have a vertical scroll bar
 *    and pages that do not.
 * 2. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 * 3. Override the user agent style margin of 8px.
 * 4. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background-color: $color_nhsuk-grey-4;
  overflow-y: scroll; /* [1] */

  @if $nhsuk-include-font-face {
    @include _nhsuk-font-face-frutiger;
    font-family: $nhsuk-font, $nhsuk-font-fallback;
  }
}

body {
  background-color: $color_nhsuk-grey-5;
  color: $nhsuk-text-color;
  font-size: $nhsuk-base-font-size;
  -moz-osx-font-smoothing: grayscale; /* [2] */
  -webkit-font-smoothing: antialiased; /* [2] */
  line-height: _nhsuk-line-height($nhsuk-base-line-height, $nhsuk-base-font-size);
  margin: 0; /* [3] */
  min-height: 100%; /* [4] */
}
