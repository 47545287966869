/* ==========================================================================
   GENERIC / #BOX-SIZING
   ========================================================================== */

/**
 * Set the global `box-sizing` state to `border-box`.
 *
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 * http://paulirish.com/2012/box-sizing-border-box-ftw
 */

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

* {

  &,
  &:before,
  &:after {
    -moz-box-sizing: inherit;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }

}
