/* ==========================================================================
   COMPONENTS/ #SELECT
   ========================================================================== */

.nhsuk-select {
  @include nhsuk-font(19);

  border: $nhsuk-border-width-form-element solid $nhsuk-form-border-color;
  box-sizing: border-box;
  height: 40px;
  max-width: 100%;
  padding: nhsuk-spacing(1);

  &:focus {
    @include nhsuk-focused-input;
  }
}

.nhsuk-select option:active,
.nhsuk-select option:checked,
.nhsuk-select:focus::-ms-value {
  background-color: $color_nhsuk-blue;
  color: $color_nhsuk-white;
}

.nhsuk-select--error {
  border: $nhsuk-border-width-form-element-error solid $nhsuk-error-color;
}
