/* ==========================================================================
   COMPONENTS / #SKIP-LINK
   ========================================================================== */

/**
 * 1. Hides the skip link off the page,
 * 2. until the link gains focus from keyboard tabbing.
 */

.nhsuk-skip-link {
  left: -9999px; /* [1] */
  padding: nhsuk-spacing(2);
  position: absolute;

  &:active,
  &:focus {
    left: nhsuk-spacing(3); /* [2] */
    top: nhsuk-spacing(3);
    z-index: 2;
  }

  &:visited {
    color: $color_nhsuk-black;
  }

}
