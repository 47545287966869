/* ==========================================================================
   COMPONENTS/ #BACK-LINK
   ========================================================================== */

/**
 * 1. Allow space for the arrow.
 * 2. Align the icon with the start of the back link.
 * 3. Align the icon with the middle of the text.
 */

.nhsuk-back-link {
  margin-bottom: nhsuk-spacing(3);
}

.nhsuk-back-link__link {
  @include nhsuk-typography-responsive(16);

  display: inline-block;
  padding-left: 16px; /* 1 */
  position: relative;
  text-decoration: none;

  .nhsuk-icon__chevron-left {
    height: 24px;
    left: -8px; /* 2 */
    position: absolute;
    top: -1px; /* 3 */
    width: 24px;
  }

  &:visited {
    color: $nhsuk-link-color;
  }

  &:hover {
    color: $nhsuk-link-hover-color;
    text-decoration: underline;

    .nhsuk-icon__chevron-left {
      fill: $nhsuk-link-hover-color;
    }
  }

  &:focus {
    .nhsuk-icon__chevron-left {
      fill: $nhsuk-focus-text-color;
    }
  }

}
