/* ==========================================================================
   COMPONENTS / #INSET-TEXT
   ========================================================================== */

/**
 * 1. Removes top margin from first element and bottom margin from last,
 *    to ensure correct spacing within the component.
 * 2. Restricts the width of the text to optimise the line length for
 *    readability.
 */

.nhsuk-inset-text {
  @include top-and-bottom(); /* [1] */
  @include reading-width(); /* [2] */

  @include nhsuk-responsive-margin(7, 'bottom');
  @include nhsuk-responsive-margin(7, 'top');
  @include nhsuk-responsive-padding(4);

  border-left: $nhsuk-border-width-inset-text solid $color_nhsuk-blue;

  @include mq($media-type: print) {
    border-color: $nhsuk-print-text-color;
  }

}
