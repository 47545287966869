/* ==========================================================================
   COMPONENTS / #FOOTER
   ========================================================================== */

.nhsuk-footer {
  @include clearfix();
  @include print-hide();
  @include nhsuk-responsive-padding(5, bottom);
  @include nhsuk-responsive-padding(5, top);

  background-color: $color_nhsuk-grey-4;
  border-top: nhsuk-spacing(1) solid $color_nhsuk-blue;
}

.nhsuk-footer__list {
  @include nhsuk-responsive-padding(4, bottom);

  list-style-type: none;
  margin: 0;
  padding-left: nhsuk-spacing(0);

  @include mq($from: desktop) {
    float: left;
    padding-bottom: 0;
    width: 75%;
  }
}

.nhsuk-footer__list-item {
  @include nhsuk-font(16);

  @include mq($from: desktop) {
    float: left;
    margin-right: nhsuk-spacing(5);
  }
}

.nhsuk-footer__list-item-link {
  color: $nhsuk-secondary-text-color;

  &:visited {
    color: $nhsuk-secondary-text-color;
  }

  &:hover {
    color: $nhsuk-text-color;
  }
}

.nhsuk-footer__copyright {
  @include nhsuk-font(16);

  color: $nhsuk-secondary-text-color;
  margin-bottom: 0;

  @include mq($from: desktop) {
    float: right;
    text-align: right;
    width: 25%;
  }
}
