/* ==========================================================================
   COMPONENTS/ #ACTION-LINK
   ========================================================================== */

/**
 * 1. Display is inline-block so the top and bottom margins/paddings are
 *    respected.
 * 2. 'Random number' is used to properly have sufficient space between icon
 *    and text.
 * 3. Position is relative so the arrow icon can display absolute.
 * 4. Text decoration none used to override default <a> styling.
 * 5. Box shadow 8px used instead of the default 4px.
 * 6. Text decoration underline used to override default <a> styling.
 */

.nhsuk-action-link {
  @include nhsuk-responsive-margin(6, 'bottom');
}

.nhsuk-action-link__link {
  @include nhsuk-font(22);

  display: inline-block; /* [1] */
  font-weight: $nhsuk-font-bold;
  padding-left: 38px; /* [2] */
  position: relative; /* [3] */
  text-decoration: none; /* [4] */

  &:hover {
    .nhsuk-action-link__text {
      text-decoration: underline; /* [6] */
    }
  }

  &:focus {
    @include nhsuk-focused-text();

    &:hover {
      .nhsuk-action-link__text {
        color: $nhsuk-focus-text-color;
        text-decoration: none;
      }
    }

  }

  @include mq($until: tablet) {
    padding-left: 26px; /* [2] */
  }

  @include mq($media-type: print) {
    color: $nhsuk-print-text-color;

    &:visited {
      color: $nhsuk-print-text-color;
    }
  }

  .nhsuk-icon__arrow-right-circle {
    @include print-color($nhsuk-print-text-color);

    fill: $color_nhsuk-green;
    height: 36px;
    left: -3px;
    position: absolute;
    top: -2px;
    width: 36px;

    @include mq($until: tablet) {
      height: 24px;
      left: -2px;
      margin-bottom: 0;
      top: 2px;
      width: 24px;
    }

  }

}
