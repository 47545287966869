// ==========================================================================
// TOOLS / #SHAPE-ARROW
// ==========================================================================

//
// Original code taken from GDS (Government Digital Service)
// https://github.com/alphagov/govuk-frontend
//
// Calculate the height of an equilateral triangle
// Multiplying half the length of the base of an equilateral triangle by the
// square root of three gives us its height. We use 1.732 as an approximation.
//
// @param {Number} $base - Length of the base of the triangle
// @return {Number} Calculated height of the triangle
//

/* stylelint-disable string-quotes, order/properties-alphabetical-order */

@function _govuk-equilateral-height($base) {
  $square-root-of-three: 1.732;

  @return ($base / 2) * $square-root-of-three;
}

//
// Arrow mixin
//
// Generate Arrows (triangles) by using a mix of transparent (1) and coloured
// borders. The coloured borders inherit the text colour of the element (2).
//
// Ensure the arrow is rendered correctly if browser colours are overridden by
// providing a clip path (3). Without this the transparent borders are
// overridden to become visible which results in a square.
//
// We need both because older browsers do not support clip-path.
//
// @param {String} $direction - Direction for arrow: up, right, down, left.
// @param {Number} $base - Length of the triangle 'base' side
// @param {Number} $height [null] - Height of triangle. Omit for equilateral.
// @param {String} $display [block] - CSS display property of the arrow
//

@mixin govuk-shape-arrow($direction, $base, $height: null, $display: block) {
  display: $display;

  width: 0;
  height: 0;

  border-style: solid;
  border-color: transparent; // 1

  $perpendicular: $base / 2;

  @if ($height == null) {
    $height: _govuk-equilateral-height($base);
  }

  @if $direction == "up" {
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%); // 3

    border-width: 0 $perpendicular $height $perpendicular;
    border-bottom-color: inherit; // 2
  } @else if $direction == "right" {
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%); // 3

    border-width: $perpendicular 0 $perpendicular $height;
    border-left-color: inherit; // 2
  } @else if $direction == "down" {
    clip-path: polygon(0% 0%, 50% 100%, 100% 0%); // 3

    border-width: $height $perpendicular 0 $perpendicular;
    border-top-color: inherit; // 2
  } @else if $direction == "left" {
    clip-path: polygon(0% 50%, 100% 100%, 100% 0%); // 3

    border-width: $perpendicular $height $perpendicular 0;
    border-right-color: inherit; // 2
  } @else {
    @error "Invalid arrow direction: expected `up`, `right`, `down` or `left`, got `#{$direction}`";
  }
}
