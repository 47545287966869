/* ==========================================================================
   COMPONENTS / #DO-DONT-LIST
   ========================================================================== */

/**
 * Do and Don't lists help users understand more easily what they should
 * and shouldn't do.
 *
 * 1. Uses @mixin panel-with-label from tools/_mixins.
 * 2. Uses @mixin heading-label from tools/_mixins.
 */

.nhsuk-do-dont-list {
  @include panel-with-label($color_nhsuk-white, $nhsuk-text-color, $color_nhsuk-grey-4); /* [1] */
}

.nhsuk-do-dont-list__label {
  @include heading-label($color_nhsuk-blue, $color_nhsuk-white); /* [2] */
  @include print-color($nhsuk-print-text-color);
}
